/* eslint-disable unicorn/prefer-module */
/* eslint-disable max-len */
/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useState, useMemo } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import { Loader } from "..";
import { useAuthContext } from "../../context/auth";
import { getOportunidades, getIntermediarios, getEdificiosOperativos, getUsers } from "../../utils";
import "./stylesVentasFacturasEjecutivo.scss";

export const VentasFacturasEjecutivo = () => {
  const [todasOportunidades, setTodasOportunidades] = useState([]);
  const [oportunidadesFiltradas, setOportunidadesFiltradas] = useState([]);
  const [intermediarios, setIntermediarios] = useState([]);
  const [filtroNumeroFactura, setFiltroNumeroFactura] = useState("");
  const [filtroCliente, setFiltroCliente] = useState("");
  const [filtroCobrada, setFiltroCobrada] = useState("");
  const [numerosFacturaUnicos, setNumerosFacturaUnicos] = useState([]);
  const [clientesUnicos, setClientesUnicos] = useState([]);
  const [filtroAno, setFiltroAno] = useState(new Date().getFullYear().toString());
  const [filtroMeses, setFiltroMeses] = useState([]);
  const [filtroIntermediario, setFiltroIntermediario] = useState("");
  const [intermediariosUnicos, setIntermediariosUnicos] = useState([]);
  const [edificios, setEdificios] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filtroEjecutivo, setFiltroEjecutivo] = useState("");
  const [ejecutivosUnicos, setEjecutivosUnicos] = useState([]);
  const [ejecutivos, setEjecutivos] = useState({});
  const [selectedOpportunity, setSelectedOpportunity] = useState(null);

  const { pais, id, rol } = useAuthContext();

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      }[pais] || "es-UY"),
    []
  );

  const moneda = useCallback(
    (pais) =>
      ({
        Chile: "$",
        Perú: "S/",
        Uruguay: "$",
      }[pais] || "$"),
    []
  );

  const decimales = useCallback(
    (pais) =>
      ({
        Chile: 0,
        Perú: 2,
        Uruguay: 0,
      }[pais] || 0),
    []
  );

  const logo = useMemo(
    () =>
      ({
        Chile: require("../../img/wecast-logo.png"),
        Perú: require("../../img/visiona-logo.png"),
        Uruguay: require("../../img/screenmedia-logo.png"),
        Region: require("../../img/adigital-logo.png"),
      })[pais] || require("../../img/visiona-logo.png"),
    [pais]
  );

  const mesEnLetras = useCallback((mes) => {
    const mesesEnLetras = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
    return mesesEnLetras[parseInt(mes, 10) - 1] || "";
  }, []);

  const calcularComisionVendedor = (fechaFactura) => {
    const date = new Date(fechaFactura);
    const month = date.getMonth();
  
    if (pais === "Perú") {
      return 5; // Fixed 5% commission for Peru regardless of quarter
    }
  
    if (pais === "Chile") {
      if (month >= 0 && month <= 2) return 8; // Q1 - January to March
      if (month >= 3 && month <= 5) return 7; // Q2 - April to June
      if (month >= 6 && month <= 8) return 6; // Q3 - July to September
      if (month >= 9 && month <= 11) return 5; // Q4 - October to December
    }
  
    return 0; // If no date, invalid date, or other country, default to 0
  };

  const esGerenciaOAdministracion = ["gerencia", "administrativo"].includes(rol.toLowerCase());

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [allOportunidades, allIntermediarios, edificiosData] = await Promise.all([
          getOportunidades({ pais }),
          getIntermediarios({ pais }),
          getEdificiosOperativos({ pais }),
        ]);

        // Mapear el ID del intermediario al nombre
        const oportunidadesMapeadas = allOportunidades.map((oportunidad) => {
          let intermediarioNombre = oportunidad.intermediario;
          if (intermediarioNombre && !isNaN(intermediarioNombre)) {
            const intermediarioAsociado = allIntermediarios.find(
              (intermediario) => intermediario.id === parseInt(intermediarioNombre)
            );
            intermediarioNombre = intermediarioAsociado ? intermediarioAsociado.nombre : "SIN INTERMEDIARIO";
          }
          return { ...oportunidad, intermediario: intermediarioNombre };
        });

        const userOportunidades = esGerenciaOAdministracion
          ? oportunidadesMapeadas
          : oportunidadesMapeadas.filter((oportunidad) => oportunidad.ejecutivo === id.toString());

        setTodasOportunidades(userOportunidades);
        setIntermediarios(allIntermediarios);
        setEdificios(edificiosData);

        const intermediariosUnicos = [...new Set(allIntermediarios.map((i) => i.nombre))].sort();
        setIntermediariosUnicos(intermediariosUnicos);

        // Fetch all users
        const allUsers = await getUsers();

        if (!allUsers) {
          throw new Error("Failed to fetch users");
        }

        // Create a map of user IDs to names
        const ejecutivosMap = allUsers.reduce((acc, user) => {
          acc[user.id] = user.nombre; // Assuming 'nombre' is the field for the name
          return acc;
        }, {});

        setEjecutivos(ejecutivosMap);

        setIsLoading(false); // All data is now loaded, set loading to false
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Hubo un error al cargar los datos.");
        setIsLoading(false); // Also set loading to false if there's an error to show the interface or error message
      }
    };

    fetchData();
  }, [pais, id, rol, esGerenciaOAdministracion]);

  useEffect(() => {
    const filtrarOportunidades = () => {
      const yearSeleccionado = filtroAno ? parseInt(filtroAno, 10) : null;

      const filtradas = todasOportunidades
        .filter((oportunidad) => {
          const fechaFactura = oportunidad.facturafecha ? new Date(oportunidad.facturafecha) : null;
          const mesFactura = fechaFactura ? (fechaFactura.getMonth() + 1).toString().padStart(2, "0") : null;

          const mesFiltrado =
            filtroMeses.length > 0
              ? filtroMeses.includes(mesFactura) || filtroMeses.includes(oportunidad.mes)
              : true;

          const intermediarioFiltrado = filtroIntermediario
            ? oportunidad.intermediario === filtroIntermediario
            : true;

          const perteneceAlAno = yearSeleccionado
            ? parseInt(oportunidad.ano, 10) === yearSeleccionado ||
              (fechaFactura && fechaFactura.getFullYear() === yearSeleccionado)
            : true;

          return (
            mesFiltrado &&
            intermediarioFiltrado &&
            (filtroNumeroFactura ? oportunidad.facturanumero === filtroNumeroFactura : true) &&
            (filtroCliente ? oportunidad.cliente === filtroCliente : true) &&
            (filtroCobrada
              ? filtroCobrada === "Sí"
                ? oportunidad.facturafechacobro
                : !oportunidad.facturafechacobro
              : true) &&
            perteneceAlAno &&
            (filtroEjecutivo ? oportunidad.ejecutivo === filtroEjecutivo : true) &&
            oportunidad.estatus === "100%" &&
            !oportunidad.eliminada
          );
        })
        .map((oportunidad) => {
          const intermediarioAsociado = intermediarios.find(
            (intermediario) => intermediario.nombre === oportunidad.intermediario
          );
          let comisionIntermediarioPorcentaje = 0;

          // Determinar la comisión del intermediario (considerando comision_2)
          if (intermediarioAsociado) {
            const fechaFactura = new Date(oportunidad.facturafecha || oportunidad.created_at);
            const anoFactura = fechaFactura.getFullYear();
            if (
              intermediarioAsociado.comision_2 !== null &&
              intermediarioAsociado.comision_2_fechainicio &&
              intermediarioAsociado.comision_2_fechafin
            ) {
              const fechaInicioIntermediario = new Date(intermediarioAsociado.comision_2_fechainicio);
              const fechaFinIntermediario = new Date(intermediarioAsociado.comision_2_fechafin);
              const anoInicioIntermediario = fechaInicioIntermediario.getFullYear();
              const anoFinIntermediario = fechaFinIntermediario.getFullYear();

              comisionIntermediarioPorcentaje = anoFactura >= anoInicioIntermediario && anoFactura <= anoFinIntermediario ? intermediarioAsociado.comision_2 || 0 : intermediarioAsociado.comision || 0;
            } else {
              comisionIntermediarioPorcentaje = intermediarioAsociado.comision || 0;
            }
          }

          const comisionIntermediario = oportunidad.importe * (comisionIntermediarioPorcentaje / 100);
          const comisionEdificio = calcularComisionEdificio(oportunidad, edificios);
          const neto = oportunidad.importe - comisionIntermediario - comisionEdificio;
          const fechaFactura = new Date(oportunidad.facturafecha || oportunidad.created_at);
          const comisionVendedorPercent = calcularComisionVendedor(fechaFactura);
          const comisionVendedor = neto * (comisionVendedorPercent / 100);

          return {
            ...oportunidad,
            comision: comisionIntermediarioPorcentaje,
            comisionedificio: comisionEdificio,
            comisionvendedor: comisionVendedor,
            neto: neto,
            edificiosparticipantes: String(oportunidad.edificiosparticipantes),
          };
        });

      setOportunidadesFiltradas(filtradas);

      // Actualizar listas únicas para filtros
      const numerosFactura = [...new Set(filtradas.map((o) => o.facturanumero))]
        .filter(Boolean)
        .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));
      setNumerosFacturaUnicos(numerosFactura);

      const clientes = [...new Set(filtradas.map((o) => o.cliente))].filter(Boolean).sort();
      setClientesUnicos(clientes);
    };

    const ejecutivos = [...new Set(todasOportunidades.map((o) => o.ejecutivo))].sort();
    setEjecutivosUnicos(ejecutivos);

    if (todasOportunidades.length > 0 && edificios.length > 0) {
      filtrarOportunidades();
    }
  }, [
    filtroMeses,
    filtroNumeroFactura,
    filtroCliente,
    filtroCobrada,
    filtroAno,
    filtroIntermediario,
    todasOportunidades,
    intermediarios,
    edificios,
    filtroEjecutivo,
  ]);

  const calcularComisionEdificio = (oportunidad, edificios) => {
    if (!oportunidad.edificiosparticipantes || typeof oportunidad.edificiosparticipantes !== "string") {
      return 0;
    }
  
    const edificiosParticipantes = oportunidad.edificiosparticipantes.split(",").map(Number);
  
    let totalComision = 0;
  
    const importeTotal = oportunidad.importe;
  
    // Determine the date to use for commission calculation
    let fechaFactura;
    if (oportunidad.facturafecha) {
      // Use the actual invoice date if available
      fechaFactura = new Date(oportunidad.facturafecha);
    } else if (oportunidad.mes) {
      // Fallback to oportunidad.mes and oportunidad.ano
      const mes = parseInt(oportunidad.mes, 10) || 1; // Default to January if mes is invalid
      const ano = oportunidad.ano ? parseInt(oportunidad.ano, 10) : new Date().getFullYear(); // Default to current year if ano is missing
      fechaFactura = new Date(ano, mes - 1, 1); // Months are 0-based, set day to 1
    } else {
      // If neither facturafecha nor mes is available, return 0 (or handle differently if needed)
      return 0;
    }
  
    edificiosParticipantes.forEach((edificioId) => {
      const edificio = edificios.find((ed) => ed.id === edificioId);
      if (edificio) {
        let porcentajeComision = 0;
  
        // Check if the date falls within any commission period
        const periods = [
          { inicio: edificio.comision_1_fechainicio, fin: edificio.comision_1_fechafin, porcentaje: edificio.comision_1_porcentaje },
          { inicio: edificio.comision_2_fechainicio, fin: edificio.comision_2_fechafin, porcentaje: edificio.comision_2_porcentaje },
          { inicio: edificio.comision_3_fechainicio, fin: edificio.comision_3_fechafin, porcentaje: edificio.comision_3_porcentaje },
        ].filter((p) => p.inicio && p.fin);
  
        for (const period of periods) {
          if (fechaFactura >= new Date(period.inicio) && fechaFactura <= new Date(period.fin)) {
            porcentajeComision = period.porcentaje || 0;
            break;
          }
        }
  
        // Calculate screen proportion
        const totalPantallas = edificios.reduce(
          (sum, ed) =>
            edificiosParticipantes.includes(ed.id)
              ? sum + (Number(ed.pantallas || 0)) + (Number(ed.totems || 0)) + (Number(ed.espera || 0)) + (Number(ed.cowork || 0))
              : sum,
          0
        );
        const proporcion =
          totalPantallas > 0
            ? (Number(edificio.pantallas || 0) + Number(edificio.totems || 0) + Number(edificio.espera || 0) + Number(edificio.cowork || 0)) / totalPantallas
            : 1;
  
        totalComision += (importeTotal * porcentajeComision) / 100 * proporcion;
      }
    });
  
    return !isNaN(totalComision) ? totalComision : 0;
  };

  const calcularTotales = () => {
    let totalVenta = 0;
    let totalComisionIntermediario = 0;
    let totalComisionEdificio = 0;
    let totalComisionVendedor = 0;

    oportunidadesFiltradas.forEach((oportunidad) => {
      const importe = oportunidad.importe || 0;
      totalVenta += importe;
      totalComisionIntermediario += importe * (oportunidad.comision / 100);
      const comisionEdificio = calcularComisionEdificio(oportunidad, edificios);
      totalComisionEdificio += comisionEdificio;
      totalComisionVendedor += oportunidad.comisionvendedor || 0;
    });

    const totalNeto = totalVenta - totalComisionIntermediario - totalComisionEdificio;

    return {
      totalVenta,
      totalComisionIntermediario,
      totalComisionEdificio,
      totalNeto,
      totalComisionVendedor,
    };
  };

  const totales = calcularTotales();
  const fecha = { day: "2-digit", month: "2-digit", year: "numeric" };

  if (todasOportunidades.length === 0 || intermediarios.length === 0) return <Loader />;

  const renderMesOptions = () =>
    ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"].map((mes, index) => (
      <div key={index} className="form-check" style={{ marginBottom: "5px" }}>
        <input
          className="form-check-input"
          type="checkbox"
          id={`mes${index}`}
          value={index < 9 ? `0${index + 1}` : `${index + 1}`}
          checked={filtroMeses.includes(index < 9 ? `0${index + 1}` : `${index + 1}`)}
          onChange={(e) => handleMesChange(e.target.value)}
          style={{ marginLeft: "-15px" }}
        />
        <label className="form-check-label" htmlFor={`mes${index}`} style={{ marginLeft: "10px" }}>
          {mes}
        </label>
      </div>
    ));

  const handleMesChange = (mes) => {
    setFiltroMeses((prev) => (prev.includes(mes) ? prev.filter((item) => item !== mes) : [...prev, mes]));
  };

  const BuildingDetailsModal = ({ opportunity, buildings, moneda, localString, decimales, onClose }) => {
    let buildingDetails = [];
  
    if (opportunity.edificiosparticipantes && typeof opportunity.edificiosparticipantes === "string") {
      const edificiosParticipantes = opportunity.edificiosparticipantes.split(",").map(Number);
  
      // Calculate total screens across all participating buildings
      const totalPantallas = buildings.reduce(
        (sum, ed) =>
          edificiosParticipantes.includes(ed.id)
            ? sum + (Number(ed.pantallas || 0)) + (Number(ed.totems || 0)) + (Number(ed.espera || 0)) + (Number(ed.cowork || 0))
            : sum,
        0
      );
  
      let totalComision = 0;
      let totalInvestment = 0;
  
      // Determine the date to use for commission calculation
      let fechaFactura;
      if (opportunity.facturafecha) {
        // Use the actual invoice date if available
        fechaFactura = new Date(opportunity.facturafecha);
      } else {
        // Fallback to oportunidad.mes and oportunidad.ano
        const mes = opportunity.mes ? parseInt(opportunity.mes, 10) : 1; // Default to January if mes is missing
        const ano = opportunity.ano ? parseInt(opportunity.ano, 10) : new Date().getFullYear(); // Default to current year if ano is missing
        // Construct a date using the 1st day of the projected month
        fechaFactura = new Date(ano, mes - 1, 1); // Months are 0-based in JavaScript, so subtract 1
      }
  
      buildingDetails = edificiosParticipantes
        .map((buildingId) => {
          const building = buildings.find((b) => b.id === buildingId);
          if (!building) {
            console.warn(`Building with ID ${buildingId} not found.`);
            return null;
          }
  
          // Determine the commission percentage based on the date
          let comisionPercent = 0;
          const periods = [
            { inicio: building.comision_1_fechainicio, fin: building.comision_1_fechafin, porcentaje: building.comision_1_porcentaje },
            { inicio: building.comision_2_fechainicio, fin: building.comision_2_fechafin, porcentaje: building.comision_2_porcentaje },
            { inicio: building.comision_3_fechainicio, fin: building.comision_3_fechafin, porcentaje: building.comision_3_porcentaje },
          ].filter((p) => p.inicio && p.fin);
  
          for (const period of periods) {
            if (fechaFactura >= new Date(period.inicio) && fechaFactura <= new Date(period.fin)) {
              comisionPercent = period.porcentaje || 0;
              break;
            }
          }
  
          // Calculate screen count for this building
          const pantallasEdificio =
            Number(building.pantallas || 0) +
            Number(building.totems || 0) +
            Number(building.espera || 0) +
            Number(building.cowork || 0);
          const proporcion = totalPantallas > 0 ? pantallasEdificio / totalPantallas : 1;
  
          // Calculate the commission for this building
          const comisionEdificio = (opportunity.importe * (comisionPercent / 100)) * proporcion;
          totalComision += comisionEdificio;
  
          // Calculate proportionate investment
          const importeInversion = opportunity.importe * proporcion;
          totalInvestment += importeInversion;
  
          return {
            name: building.edificio || "Unknown",
            comision: comisionEdificio.toFixed(0),
            percent: comisionPercent.toFixed(1),
            pantallas: pantallasEdificio,
            importeInversion: importeInversion.toFixed(0),
          };
        })
        .filter(Boolean);
  
      // Sort buildings alphabetically by name
      buildingDetails.sort((a, b) => a.name.localeCompare(b.name));
  
      // Add totals row to buildingDetails
      buildingDetails.push({
        name: "Total",
        comision: totalComision.toFixed(0),
        percent: ((totalComision / opportunity.importe) * 100).toFixed(2),
        pantallas: totalPantallas,
        importeInversion: totalInvestment.toFixed(0),
      });
    }
  
    return (
      <Modal show={!!opportunity} onHide={onClose} dialogClassName="wide-modal">
        <Modal.Header closeButton>
          <Modal.Title>DETALLE DEL CÁLCULO DE LA COMISIÓN DE LOS EDIFICIOS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {buildingDetails.length > 0 ? (
            <div style={{ overflowX: "auto" }}>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th style={{ width: "45%" }}>EDIFICIOS INVOLUCRADOS</th>
                    <th className="text-end" style={{ width: "15%" }}>
                      PANTALLAS POR EDIFICIO
                    </th>
                    <th className="text-end" style={{ width: "15%" }}>
                      INVERSIÓN POR EDIFICIO {moneda.toUpperCase()}
                    </th>
                    <th className="text-end" style={{ width: "15%" }}>
                      COMISIÓN ASIGNADA
                    </th>
                    <th className="text-end" style={{ width: "15%" }}>
                      COMISIÓN {moneda.toUpperCase()}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {buildingDetails.map((detail, index) => (
                    <tr key={index} className={detail.name === "Total" ? "table-info" : ""}>
                      <td className="text-start">{detail.name.toUpperCase()}</td>
                      <td className="text-end">{detail.pantallas}</td>
                      <td className="text-end">{`${moneda.toUpperCase()} ${Number(
                        detail.importeInversion
                      ).toLocaleString(localString, {
                        maximumFractionDigits: decimales,
                        minimumFractionDigits: decimales,
                      })}`}</td>
                      <td className="text-end">
                        {detail.percent
                          ? `${Number(detail.percent).toLocaleString(localString, {
                            maximumFractionDigits: 1,
                            minimumFractionDigits: 1,
                          })}%`
                          : "-"}
                      </td>
                      <td className="text-end">{`${moneda.toUpperCase()} ${Number(
                        detail.comision
                      ).toLocaleString(localString, {
                        maximumFractionDigits: decimales,
                        minimumFractionDigits: decimales,
                      })}`}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>NO HAY DETALLES DE EDIFICIOS DISPONIBLES PARA ESTA OPORTUNIDAD.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={onClose}>
            CERRAR
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <div className="mis-facturas">
        <div className="logo-container mb-4">
          <img src={logo} alt={`${pais} Logo`} className="logo" />
        </div>
        <div className="table-responsive">
          {/* Mini Dashboard */}
          <div className="row justify-content-center mb-2">
            <div className="col-md-2">
              <div className="card text-white" style={{ backgroundColor: "#343a40" }}>
                <div className="card-header text-center" style={{ borderBottom: "1px solid #ffffff" }}>
                  BRUTO
                </div>
                <div className="card-body text-center">
                  <h5 className="card-title">{`${moneda(pais)} ${
                    totales.totalVenta !== undefined && totales.totalVenta !== null
                      ? totales.totalVenta.toLocaleString(localString(pais), {
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      })
                      : "0"
                  }`}</h5>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="card text-white" style={{ backgroundColor: "#343a40" }}>
                <div className="card-header text-center" style={{ borderBottom: "1px solid #ffffff" }}>
                  COMISIÓN AGENCIAS
                </div>
                <div className="card-body text-center">
                  <h5 className="card-title">{`${moneda(pais)} ${
                    totales.totalComisionIntermediario !== undefined && totales.totalComisionIntermediario !== null
                      ? totales.totalComisionIntermediario.toLocaleString(localString(pais), {
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      })
                      : "0"
                  }`}</h5>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="card text-white" style={{ backgroundColor: "#343a40" }}>
                <div className="card-header text-center" style={{ borderBottom: "1px solid #ffffff" }}>
                  COMISIÓN EDIFICIOS
                </div>
                <div className="card-body text-center">
                  <h5 className="card-title">{`${moneda(pais)} ${
                    totales.totalComisionEdificio !== undefined && totales.totalComisionEdificio !== null
                      ? totales.totalComisionEdificio.toLocaleString(localString(pais), {
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      })
                      : "0"
                  }`}</h5>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="card text-white" style={{ backgroundColor: "#343a40" }}>
                <div className="card-header text-center" style={{ borderBottom: "1px solid #ffffff" }}>
                  NETO
                </div>
                <div className="card-body text-center">
                  <h5 className="card-title">{`${moneda(pais)} ${
                    totales.totalNeto !== undefined && totales.totalNeto !== null
                      ? totales.totalNeto.toLocaleString(localString(pais), {
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      })
                      : "0"
                  }`}</h5>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="card text-white" style={{ backgroundColor: "#343a40" }}>
                <div className="card-header text-center" style={{ borderBottom: "1px solid #ffffff" }}>
                  COMISIÓN COMERCIAL
                </div>
                <div className="card-body text-center">
                  <h5 className="card-title">{`${moneda(pais)} ${
                    totales.totalComisionVendedor !== undefined && totales.totalComisionVendedor !== null
                      ? totales.totalComisionVendedor.toLocaleString(localString(pais), {
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      })
                      : "0"
                  }`}</h5>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-4 mb-3">
              {/* First row */}
              <div className="col-md-2">
                <select
                  className="form-select"
                  value={filtroNumeroFactura}
                  onChange={(e) => setFiltroNumeroFactura(e.target.value)}
                >
                  <option value="">Número de Factura</option>
                  {numerosFacturaUnicos.map((numero) => (
                    <option key={numero} value={numero}>
                      {numero}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-2">
                <select
                  className="form-select"
                  value={filtroCliente}
                  onChange={(e) => setFiltroCliente(e.target.value)}
                >
                  <option value="">Cliente Publicitario</option>
                  {clientesUnicos.map((cliente) => (
                    <option key={cliente} value={cliente}>
                      {cliente}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-2">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="white border"
                    id="dropdown-basic"
                    className={`form-control text-start ${filtroMeses.length > 0 ? "active-filter" : ""}`}
                  >
                    {filtroMeses.length > 0 ? filtroMeses.map((mes) => mesEnLetras(mes)).join(", ") : "Mes/meses"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ maxHeight: "370px", overflowY: "auto" }}>
                    {renderMesOptions()}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="col-md-2">
                <select
                  className="form-select"
                  value={filtroIntermediario}
                  onChange={(e) => setFiltroIntermediario(e.target.value)}
                >
                  <option value="">Intermediario</option>
                  {intermediariosUnicos.map((intermediario) => (
                    <option key={intermediario} value={intermediario}>
                      {intermediario}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row justify-content-center mb-3">
              {/* Second row */}
              <div className="col-md-2">
                <select className="form-select" value={filtroAno} onChange={(e) => setFiltroAno(e.target.value)}>
                  <option value="">HTD</option>
                  {["2024", "2025"].map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-2">
                <select
                  className="form-select"
                  value={filtroCobrada}
                  onChange={(e) => setFiltroCobrada(e.target.value)}
                >
                  <option value="">Cobrada</option>
                  <option value="Sí">Sí</option>
                  <option value="No">No</option>
                </select>
              </div>
              <div className="col-md-2">
                <select
                  className="form-select"
                  value={filtroEjecutivo}
                  onChange={(e) => setFiltroEjecutivo(e.target.value)}
                >
                  <option value="">Todos</option>
                  {ejecutivosUnicos.map((ejecutivoId) => (
                    <option key={ejecutivoId} value={ejecutivoId}>
                      {ejecutivos[ejecutivoId] || ejecutivoId}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <table className="table table-hover table-sm custom-table">
            <colgroup>
              <col className="status-col" />
              <col className="numero-col" span="1" />
              <col className="fecha-col" span="1" />
              <col className="importe-col" span="1" />
              <col className="cliente-col" />
              <col className="agencia-col" span="1" />
              <col className="porcentaje-inter-col" span="1" />
              <col className="importe-inter-col" span="1" />
              <col className="participantes-col" span="1" />
              <col className="porcentaje-edificios-col" span="1" />
              <col className="importe-edificios-col" span="1" />
              <col className="neto-col" />
              <col className="comision-comercial-col" />
            </colgroup>
            <thead className="text-center align-middle">
              <tr>
                <th rowSpan="2" className="status-col">
                  STATUS
                </th>
                <th colSpan="3" className="factura-col">
                  FACTURA
                </th>
                <th rowSpan="2" className="cliente-col">
                  CLIENTE PUBLICITARIO
                </th>
                <th colSpan="3" className="intermediario-col">
                  INTERMEDIARIO
                </th>
                <th colSpan="3" className="comision-edificios-col">
                  COMISIÓN EDIFICIOS
                </th>
                <th rowSpan="2" className="neto-col">
                  NETO
                </th>
                <th rowSpan="2" className="comision-comercial-col">
                  COMISIÓN COMERCIAL
                </th>
              </tr>
              <tr>
                <th className="numero-col">NÚMERO</th>
                <th className="fecha-col">FECHA</th>
                <th className="importe-col">IMPORTE</th>
                <th className="agencia-col">AGENCIA</th>
                <th className="porcentaje-inter-col">%</th>
                <th className="importe-inter-col">IMPORTE</th>
                <th className="participantes-col">DETALLE</th>
                <th className="porcentaje-edificios-col">%</th>
                <th className="importe-edificios-col">IMPORTE</th>
              </tr>
            </thead>
            <tbody>
              {oportunidadesFiltradas.length === 0 ? (
                <tr>
                  <td colSpan="13" className="text-center">
                    No hay facturas ni ventas según los filtros seleccionados :(
                  </td>
                </tr>
              ) : (
                oportunidadesFiltradas.map(
                  ({
                    cliente,
                    comision,
                    comisionedificio,
                    comisionvendedor,
                    estatus,
                    facturafecha,
                    facturanumero,
                    id,
                    importe,
                    intermediario,
                    neto,
                    edificiosparticipantes,
                  }) => (
                    <tr key={id}>
                      <td className="bg-success text-white status-col">{estatus}</td>
                      <td className="numero-col">{facturanumero ? facturanumero : "Pendiente"}</td>
                      <td className="fecha-col">
                        {facturafecha
                          ? new Date(facturafecha).toLocaleDateString("es-ES", fecha)
                          : "Pendiente"}
                      </td>
                      <td className="text-end importe-col">{`${moneda(pais)} ${
                        importe?.toLocaleString(localString(pais), {
                          maximumFractionDigits: decimales(pais),
                          minimumFractionDigits: decimales(pais),
                        }) || "error"
                      }`}</td>
                      <td className="cliente-col">{cliente}</td>
                      <td className="agencia-col">{intermediario}</td>
                      <td className="text-center porcentaje-inter-col">
                        {intermediario === "DIRECTO" || intermediario === "SIN INTERMEDIARIO" ? "-" : `${comision}%`}
                      </td>
                      <td className="text-end importe-inter-col">
                        {intermediario === "DIRECTO" || intermediario === "SIN INTERMEDIARIO"
                          ? "-"
                          : `${moneda(pais)} ${(importe * (comision / 100)).toLocaleString(localString(pais), {
                            maximumFractionDigits: decimales(pais),
                            minimumFractionDigits: decimales(pais),
                          })}`}
                      </td>
                      <td className="participantes-col">
                        <span
                          style={{
                            cursor: "pointer",
                            color: "blue",
                            textDecoration: "underline",
                            maxWidth: "100%",
                            overflow: "visible",
                            textOverflow: "clip",
                            display: "block",
                          }}
                          onClick={() =>
                            setSelectedOpportunity({
                              cliente,
                              comision,
                              comisionedificio,
                              comisionvendedor,
                              estatus,
                              facturafecha,
                              facturanumero,
                              id,
                              importe,
                              intermediario,
                              neto,
                              edificiosparticipantes,
                            })
                          }
                        >
                          Detalle
                        </span>
                      </td>
                      <td className="text-center porcentaje-edificios-col">{`${Number(
                        (comisionedificio / importe) * 100
                      ).toLocaleString(localString(pais), {
                        maximumFractionDigits: 1,
                        minimumFractionDigits: 1,
                      })}%`}</td>
                      <td className="text-end importe-edificios-col">{`${moneda(pais)} ${(
                        comisionedificio || 0
                      ).toLocaleString(localString(pais), {
                        maximumFractionDigits: decimales(pais),
                        minimumFractionDigits: decimales(pais),
                      })}`}</td>
                      <td className="text-end neto-col">{`${moneda(pais)} ${neto.toLocaleString(localString(pais), {
                        maximumFractionDigits: decimales(pais),
                        minimumFractionDigits: decimales(pais),
                      })}`}</td>
                      <td className="text-end comision-comercial-col">{`${moneda(pais)} ${(
                        comisionvendedor || 0
                      ).toLocaleString(localString(pais), {
                        maximumFractionDigits: decimales(pais),
                        minimumFractionDigits: decimales(pais),
                      })}`}</td>
                    </tr>
                  )
                )
              )}
            </tbody>
          </table>
        </div>
        {selectedOpportunity && (
          <BuildingDetailsModal
            opportunity={selectedOpportunity}
            buildings={edificios}
            moneda={moneda(pais)}
            localString={localString(pais)}
            decimales={decimales(pais)}
            onClose={() => setSelectedOpportunity(null)}
          />
        )}
      </div>
    </>
  );
};